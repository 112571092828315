import React from "react";
import { Icon } from "@iconify/react";
import Div from "../Div";

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <a
        href="https://telegram.org/"
        target="_blank"
        rel="noreferrer"
        className="cs-center"
      >
        <Icon icon="fa6-brands:telegram" />
      </a>
      <a
        href="https://www.facebook.com/"
        target="_blank"
        rel="noreferrer"
        className="cs-center"
      >
        <Icon icon="fa6-brands:facebook" />
      </a>
      <a
        href="https://www.instagram.com/"
        target="_blank"
        rel="noreferrer"
        className="cs-center"
      >
        <Icon icon="fa6-brands:instagram" />
      </a>
      <a
        href="https://youtube.com/"
        target="_blank"
        rel="noreferrer"
        className="cs-center"
      >
        <Icon icon="fa6-brands:youtube" />
      </a>
    </Div>
  );
}
