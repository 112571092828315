import React from "react";
import Div from "../Div";

export default function TagWidget({ title, data }) {
  return (
    <>
      <h4 className="cs-sidebar_widget_title">{title}</h4>
      <Div className="tagcloud">
        {data?.map((tag, index) => (
          <span href="/" className="tag-cloud-link" key={index}>
            {tag.title}
          </span>
        ))}
      </Div>
    </>
  );
}
