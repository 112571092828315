import React from "react";
import { Link } from "react-router-dom";

export default function MenuWidget({ menuHeading, variant, menuItems }) {
  return (
    <>
      {menuHeading && <h2 className="cs-widget_title">{menuHeading}</h2>}
      <ul
        className={`${
          variant ? `cs-menu_widget ${variant}` : "cs-menu_widget cs-style1"
        } cs-mp0`}
      >
        {menuItems &&
          menuItems?.map((item, index) => (
            <li key={index}>
              {item.href ? (
                <Link to={item.href}>{item.title}</Link>
              ) : (
                item.title
              )}
            </li>
          ))}
      </ul>
    </>
  );
}
