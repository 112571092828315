export const Logo = ({
  fill0 = "black",
  fill1 = "#2B2A29",
  className = "",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="28.8246mm"
      height="25.5158mm"
      version="1.1"
      viewBox="0 0 2882.46 2551.58"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_2051553011168">
          <polygon
            fill={fill0}
            points="1841.47,1608.03 2133.82,1609.81 1496.6,-0.03 1262.68,1 "
          />
          <polygon
            fill={fill0}
            points="748.65,1608.93 1011.04,1609.77 1367.69,603.37 1119.51,602.91 "
          />
        </g>
        <path
          fill={fill1}
          fillRule="nonzero"
          d="M338.56 1959.94l228.2 591.66 -111.2 0 -56.43 -156.84 -234 0 -58.09 156.84 -107.05 0 228.2 -591.66 110.37 0zm33.19 356.82l-87.96 -248.95 -92.11 248.95 180.07 0zm488.7 -355.99l256.41 0c63.62,0 113.82,15.07 150.61,45.22 36.79,30.15 55.19,75.1 55.19,134.85 0,56.43 -17.16,101.11 -51.45,134.02 -71.32,68.43 -212.04,49.37 -307.04,49.37l0 227.37 -103.72 0 0 -590.83zm103.72 86.3l0 193.35 144.39 0c75.24,0 112.86,-32.37 112.86,-97.09 0,-64.17 -40.68,-96.26 -121.99,-96.26l-135.26 0zm1078.14 -104.82l0 87.13 -322.8 0 0 156.84 299.57 0 0 83.81 -299.57 0 0 174.26 327.78 0 0 88.79 -430.67 0 0 -590.83 425.69 0zm296.63 590.83l210.78 -306.2 -195.84 -284.63 123.64 0 134.43 209.11 140.24 -209.11 116.18 0 -195.84 284.63 209.94 306.2 -126.13 0 -147.71 -225.71 -150.19 225.71 -119.5 0z"
        />
      </g>
    </svg>
  );
};

export const LogoIcon = ({ fill = "black" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="13.8514mm"
      height="16.0984mm"
      version="1.1"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 1385.14 1609.84"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_2051550481520">
          <polygon
            fill={fill}
            points="1091.11,1615.93 1383.46,1617.71 746.24,7.87 512.32,8.9 "
          />
          <polygon
            fill={fill}
            points="-1.71,1616.83 260.68,1617.67 617.33,611.27 369.15,610.81 "
          />
        </g>
      </g>
    </svg>
  );
};

export const LogoText = ({ fill = "#2B2A29" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="28.8246mm"
      height="6.0934mm"
      version="1.1"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 2882.46 609.34"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          fill={fill}
          fillRule="nonzero"
          d="M332 17.82l228.2 591.66 -111.2 0 -56.43 -156.84 -234 0 -58.09 156.84 -107.05 0 228.2 -591.66 110.37 0zm33.19 356.82l-87.96 -248.95 -92.11 248.95 180.07 0zm488.7 -355.99l256.41 0c63.62,0 113.82,15.07 150.61,45.22 36.79,30.15 55.19,75.1 55.19,134.85 0,56.43 -17.16,101.11 -51.45,134.02 -71.32,68.43 -212.04,49.37 -307.04,49.37l0 227.37 -103.72 0 0 -590.83zm103.72 86.3l0 193.35 144.39 0c75.24,0 112.86,-32.37 112.86,-97.09 0,-64.17 -40.68,-96.26 -121.99,-96.26l-135.26 0zm1078.14 -104.82l0 87.13 -322.8 0 0 156.84 299.57 0 0 83.81 -299.57 0 0 174.26 327.78 0 0 88.79 -430.67 0 0 -590.83 425.69 0zm296.63 590.83l210.78 -306.2 -195.84 -284.63 123.64 0 134.43 209.11 140.24 -209.11 116.18 0 -195.84 284.63 209.94 306.2 -126.13 0 -147.71 -225.71 -150.19 225.71 -119.5 0z"
        />
      </g>
    </svg>
  );
};
